import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useLyout = () => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allFile(filter: {name: {eq: "layout"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              name
              main_avatar
              social_networks_list {
                social_network_icon_string
                social_network_name
                social_network_url
              }
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};