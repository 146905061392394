import React from 'react';
import Avatar from './Avatar';
import { useLyout } from './data';
import SocialNetworks from './SocialNetworks';

const Profile = () => {
  const { name, main_avatar, social_networks_list } = useLyout();

  return (
    <div className="profile">
      <Avatar avatar={{ name, mainAvatar: main_avatar }} />
      <SocialNetworks socialNetworks={social_networks_list} />
    </div>
  );
}

export default Profile