import React from 'react';
import Hero from './Hero/index';
import Main from './Main';

const Sections = () => (
  <React.Fragment>
    <Hero />
    <Main />
  </React.Fragment>
);

export default Sections;