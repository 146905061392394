import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useContact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allFile(filter: {name: {eq: "contact"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              section_title
              contact_description
              contact_items {
                contact_item_name
                contact_item_description
                icon_string
              }
              contact_location
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
