import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useSkills = () => {
  const data = useStaticQuery(graphql`
    query SkillsQuery {
      allFile(filter: {name: {eq: "skills"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              section_title
              skills_list_left {
                skill_name
                skill_percentage
              }
              skills_list_right {
                skill_name
                skill_percentage
              }
              skills_description
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
