import React from 'react';

import About from './About/index';
import Facts from './Facts/index';
import Skills from './Skills/index';
import Resume from './Resume/index';
import Portfolio from './Portfolio/index';
import Services from './Services/index';
import Testimonials from './Testimonials/index';
import Contact from './Contact/index';

const Main = () => {

  return (
    <main id="main">
      <About />
      <Facts />
      <Skills />
      <Resume />
      <Portfolio />
      {/*<Services />*/}
      <Testimonials />
      <Contact />
    </main>
  );
};

export default Main;