import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useFacts = () => {
  const data = useStaticQuery(graphql`
    query FactsQuery {
      allFile(filter: {name: {eq: "facts"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              section_title
              facts_description
              facts_list {
                fact_description
                fact_number
                icon_string
              }
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
