import React, { useEffect } from 'react';
import { usePortfolio } from './data';

const mountPortfolio = () => {
  function initializePortFolioAndIsotopeFilter() {
    // Porfolio isotope and filter
    var portfolioIsotope = $('.portfolio-container').isotope({
      itemSelector: '.portfolio-item',
      layoutMode: 'fitRows'
    });

    $('#portfolio-flters li').on('click', function () {
      $("#portfolio-flters li").removeClass('filter-active');
      $(this).addClass('filter-active');

      portfolioIsotope.isotope({
        filter: $(this).data('filter')
      });
      aos_init();
    });

    // Initiate venobox (lightbox feature used in portofilo)
    $(document).ready(function () {
      $('.venobox').venobox();
    });
  }

  initializePortFolioAndIsotopeFilter();

  // Portfolio details carousel
  $(".portfolio-details-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    items: 1
  });
};

const sanitizeStringForFilter = stringText => (
  stringText.replace(/\s/g, '-')
);

const getFilter = (portfolioItems) => {
  const portfolioFilter = new Set();
  portfolioItems.forEach(element => {
    portfolioFilter.add(element.item_tag.toLowerCase());
  });

  return Array.from(portfolioFilter).map(filter => {
    return {
      name: filter,
      searchTermLabel: sanitizeStringForFilter(filter)
    }
  });
};

const PortfolioItem = (element, key) => {
  const { item_image, item_name, item_tag, item_url } = element;
  const filterTag = sanitizeStringForFilter(item_tag.toLowerCase());

  return (
    <div className={`col-lg-4 col-md-6 portfolio-item filter-${filterTag}`} key={key}>
      <div className="portfolio-wrap">
        <img src={item_image} className="img-fluid" alt={item_name} />
        <div className="portfolio-links">
          <a href={item_image} data-gall="portfolioGallery" className="venobox" title="App 1"><i className="bx bx-plus" /></a>
          {item_url ? <a href={item_url} title="More Details"><i className="bx bx-link" /></a> : null }
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  useEffect(mountPortfolio);
  const { section_title, portfolio_description, portfolio_list } = usePortfolio();

  const filters = getFilter(portfolio_list);

  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{portfolio_description}</p>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">All</li>
              {filters.map((filter, key) => (
                <li data-filter={`.filter-${filter.searchTermLabel}`} key={key}>{filter.name}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={100}>
          {portfolio_list.map(PortfolioItem)}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;