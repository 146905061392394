import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PageContentLegacy from "../components/page-content-legacy"

// Styles dependencies
import '../../static/vendor/bootstrap/css/bootstrap.min.css';
import '../../static/vendor/bootstrap/css/bootstrap.min.css';
import '../../static/vendor/icofont/icofont.min.css';
import '../../static/vendor/boxicons/css/boxicons.min.css';
import '../../static/vendor/venobox/venobox.css';
import '../../static/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../static/vendor/aos/aos.css';

// Style for the template
import '../styles/theme.scss';

const IndexPage = () => (
  <React.Fragment>
    <Layout>
      <SEO title="Home" />
      <PageContentLegacy/>
    </Layout>
  </React.Fragment>
)

export default IndexPage
