import React from 'react';
import { useResume } from './data';
import { safelyConvertMDtoHTML } from '../../../../utils/markdown-handling.util';

const ResumeContent = (resume_section, resumeSectionIndex) => {
  const { resume_section_title, bullets } = resume_section;

  return (
    <React.Fragment key={resumeSectionIndex}>
      <h3 className="resume-title">{resume_section_title}</h3>
      {bullets.map((bullet, bulletIndex) => {
        const { body_inside_list, bullet_date_range, bullet_title } = bullet;
        const body = safelyConvertMDtoHTML({ markdown: body_inside_list });

        return (
          <div className="resume-item" key={bulletIndex}>
            <h4>{bullet_title}</h4>
            {bullet_date_range ? <h5>{bullet_date_range}</h5> : null}
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        );
      })}
    </React.Fragment>
  );
}

const Resume = () => {
  const { resume_content_left, resume_content_right, resume_text, section_title } = useResume();

  return (
    <section id="resume" className="resume">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{resume_text}</p>
        </div>
        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            {resume_content_left.map(ResumeContent)}
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
            {resume_content_right.map(ResumeContent)}
          </div>
        </div>
      </div>
    </section>
  )
};

export default Resume