import React, { useEffect } from 'react';
import Header from './shared/Header/index';
import Sections from './Sections/index';

const mountCustomTemplateLogic = () => {
  /**
  * Template Name: iPortfolio - v1.4.0
  * Template URL: https://bootstrapmade.com/iportfolio-bootstrap-portfolio-websites-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  */

  // Back to top button
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });

  $('.back-to-top').click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1500, 'easeInOutExpo');
    return false;
  });

  // jQuery counterUp
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 1000
  });
};

const PageContentLegacy = () => {
  useEffect(mountCustomTemplateLogic);

  return (
    <div>
      {/* ======= Mobile nav toggle button ======= */}
      <button type="button" className="mobile-nav-toggle d-xl-none"><i className="icofont-navigation-menu" /></button>
      <Header />
      <Sections />
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="container">
          <div className="copyright">
            {/*© Copyright <strong><span>iPortfolio</span></strong>*/}
          </div>
        </div>
      </footer>{/* End  Footer */}
      <a href="#" className="back-to-top"><i className="icofont-simple-up" /></a>
    </div>
  )
};

export default PageContentLegacy;