import React from 'react';
import { useAbout } from './data';
import { safelyConvertMDtoHTML } from '../../../../utils/markdown-handling.util';

const Bullets = (element, index) => {
  const { bullet } = element;
  const parsedBullet = safelyConvertMDtoHTML({ markdown: bullet, removePRootTag: true });

  return (
    <span key={index} dangerouslySetInnerHTML={{ __html: `<li><i class="icofont-rounded-right"></i>${parsedBullet}</li>` }} />
  )
};

const About = () => {
  const { about_bullet_left, about_bullet_right, about_description, about_image, about_paragraph, section_title, general_about_description, about_desctiption_title } = useAbout();

  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{general_about_description}</p>
        </div>
        <div className="row">
          <div className="col-lg-4" data-aos="fade-right">
            <img src={about_image} className="img-fluid" alt="Profile image" />
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>{about_desctiption_title}</h3>
            <p className="font-italic">
              {about_description}
            </p>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  {about_bullet_left.map(Bullets)}
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  {about_bullet_right.map(Bullets)}
                </ul>
              </div>
            </div>
            <p>
              {about_paragraph}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;