import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../services/data.service';

export const useHero = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      allFile(filter: {name: {eq: "hero"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              hero_name
              hero_image
              hero_skills_list {
                skill
              }
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
