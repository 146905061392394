import React, { useEffect } from 'react';
import { useTestimonials } from './data';

const mountTestimonials = () => {
  // Testimonials carousel (uses the Owl Carousel library)
  $(".testimonials-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      900: {
        items: 3
      }
    }
  });
};

const getTestimonialSafaAvatarImage = ({ avatarSrc,  testimonialsDefaultAvatar}) => avatarSrc || testimonialsDefaultAvatar;

const Testimonial = ({ testimonialsDefaultAvatar }) => (testimonial, key) => {
  const { occupation, person_name, person_avatar, quote } = testimonial;

  return (
    <div className="testimonial-item" data-aos="fade-up" key={key}>
      <p>
        <i className="bx bxs-quote-alt-left quote-icon-left" />
        {quote}
        <i className="bx bxs-quote-alt-right quote-icon-right" />
      </p>
      <img
        src={getTestimonialSafaAvatarImage({ avatarSrc: person_avatar, testimonialsDefaultAvatar })}
        className="testimonial-img"
        alt={`${person_name} testimonial avatar`}
      />
      <h3>{person_name}</h3>
      <h4>{occupation}</h4>
    </div>
  );
};

const Testimonials = () => {
  useEffect(mountTestimonials);

  const { section_title, testimonials_description, testimonials_list, testimonials_default_avatar } = useTestimonials();

  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{testimonials_description}</p>
        </div>
        <div className="owl-carousel testimonials-carousel">
          {testimonials_list.map(Testimonial({ testimonialsDefaultAvatar: testimonials_default_avatar }))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;