import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const usePortfolio = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      allFile(filter: {name: {eq: "portfolio"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              section_title
              portfolio_description
              portfolio_list {
                item_image
                item_name
                item_tag
                item_url
              }
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};