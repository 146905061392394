import React from 'react';
import { useFacts } from './data';
import { safelyConvertMDtoHTML } from '../../../../utils/markdown-handling.util';

const Facts = () => {
  const { section_title, facts_description, facts_list } = useFacts();

  return (
    <section id="facts" className="facts">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{facts_description}</p>
        </div>
        <div className="row no-gutters">
          {facts_list.map((fact, key) => {
            const { fact_description, fact_number, icon_string } = fact;
            const parsedFactDescription = safelyConvertMDtoHTML({ markdown: fact_description, removePRootTag: true })

            return (
              <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" key={key}>
                <div className="count-box">
                  <i className={`icofont-${icon_string}`} />
                  <span data-toggle="counter-up">{fact_number}</span>
                  <p dangerouslySetInnerHTML= {{ __html: parsedFactDescription }} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
}

export default Facts;