import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useResume = () => {
  const data = useStaticQuery(graphql`
    query ResumeQuery {
      allFile(filter: {name: {eq: "resume"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              resume_content_left {
                bullets {
                  body_inside_list
                  bullet_date_range
                  bullet_title
                }
                resume_section_title
              }
              resume_content_right {
                bullets {
                  body_inside_list
                  bullet_date_range
                  bullet_title
                }
                resume_section_title
              }
              resume_text
              section_title
              title
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
