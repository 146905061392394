import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useTestimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      allFile(filter: {name: {eq: "testimonials"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              section_title
              testimonials_description
              testimonials_list {
                occupation
                person_name
                person_avatar
                quote
              }
              testimonials_default_avatar
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};