import { graphql, useStaticQuery } from 'gatsby';
import { getPagesData } from '../../../../services/data.service';

export const useAbout = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allFile(filter: {name: {eq: "about"}}) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              about_bullet_left {
                bullet
              }
              about_bullet_right {
                bullet
              }
              about_description
              about_image
              about_paragraph
              section_title
              general_about_description
              about_desctiption_title 
            }
          }
        }
      }
    }
  `);

  return getPagesData(data);
};
