import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';

import visit from 'unist-util-visit';

const removePRootTagPlugin = () => tree => {
  const constants = {
    ROOT: 'root',
    PARAGRAPH: 'paragraph'
  };

  visit(
    tree,
    node => {
      if (node.type === constants.ROOT && node.children[0] && node.children[0].type === constants.PARAGRAPH) {
        node.children = node.children[0].children
      }
    }
  );
};

const getRemarkHtmlConfig = () => remark().use(recommended).use(remarkHtml, { sanitize: true });

const mdToHtmlWithNoRootP = markdown => getRemarkHtmlConfig().use(removePRootTagPlugin).processSync(markdown).toString();

const mdToHtml = markdown => getRemarkHtmlConfig().processSync(markdown).toString();

export const safelyConvertMDtoHTML = ({ markdown, removePRootTag = false }) => {
  if (removePRootTag) {
    return mdToHtmlWithNoRootP(markdown);
  }

  return mdToHtml(markdown);
}