import React from 'react';

const SocialNetwork = (socialNetwork, key) => {
  const { social_network_icon_string, social_network_name, social_network_url } = socialNetwork;

  return (
    <a href={social_network_url} className={social_network_icon_string} key={key}>
      <i className={`bx bxl-${social_network_icon_string}`} />
    </a>
  );
};

const SocialNetworks = ({ socialNetworks }) => (
  <div className="social-links mt-3 text-center">
    {socialNetworks.map(SocialNetwork)}
  </div>
);

export default SocialNetworks;