import React, { useEffect } from 'react';
import { useSkills } from './data';

const mountSkills = () => {
  // Skills section
  $('.skills-content').waypoint(function () {
    $('.progress .progress-bar').each(function () {
      $(this).css("width", $(this).attr("aria-valuenow") + '%');
    });
  }, {
    offset: '80%'
  });
};

const Skill = (skill, key) => {
  const { skill_name, skill_percentage } = skill;

  return (
    <div className="progress" key={key}>
      <span className="skill">{skill_name}<i className="val">{skill_percentage}%</i></span>
      <div className="progress-bar-wrap">
        <div className="progress-bar" role="progressbar" aria-valuenow={skill_percentage} aria-valuemin={0} aria-valuemax={100} />
      </div>
    </div>
  );
};

const Skills = () => {
  useEffect(mountSkills);
  const { section_title, skills_list_left, skills_list_right, skills_description } = useSkills();

  return (
    <section id="skills" className="skills section-bg">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{skills_description}</p>
        </div>
        <div className="row skills-content">
          <div className="col-lg-6" data-aos="fade-up">
            {skills_list_left.map(Skill)}
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
            {skills_list_right.map(Skill)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;