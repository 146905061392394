import React, { useEffect } from 'react';
import { useContact } from './data';
import { initializeContactForm } from './validate-contact-form.service';

const ContactItem = (item, key) => {
  const { contact_item_name, contact_item_description, icon_string } = item;

  return (
    <div key={key} className='contact-item'>
      <i className={`icofont-${icon_string}`} />
      <h4>{contact_item_name}</h4>
      <p>{contact_item_description}</p>
    </div>
  );
};

const Contact = () => {
  useEffect(initializeContactForm);
  const { section_title, contact_description, contact_items, contact_location } = useContact();
  /** 
   * TODO: Use google maps API to get the map configurable from the CMS,
   * for now we will just use the embeded url in the cms
   * const [ longitude, latitude ] = getCoordinates(contact_location);
   * Find the definition of getCoordinates at:
   * https://github.com/facerdistrict-web-page/website/blob/master/src/components/EventDetails.js#L24
   * 
   * Once we use the google maps api, we can start using this getCoordinates function
   * that will basically extract the longitude and latitude of the location
   * sent by the netlify cms map widget https://www.netlifycms.org/docs/widgets/#map
   */

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2>{section_title}</h2>
          <p>{contact_description}</p>
        </div>
        <div className="row" data-aos="fade-in">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              {contact_items.map(ContactItem)}
              <iframe
                src={contact_location}
                frameBorder={0}
                style={{ border: 0, width: '100%', height: 290 }}
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form method="post" role="form" className="php-email-form" data-netlify='true' name='contact' encType="application/x-www-form-urlencoded" action="/">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input type="text" name="name" className="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div className="validate" />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Email</label>
                  <input type="email" className="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                  <div className="validate" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Subject</label>
                <input type="text" className="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div className="validate" />
              </div>
              <div className="form-group">
                <label htmlFor="name">Message</label>
                <textarea className="form-control" name="message" rows={10} data-rule="required" data-msg="Please write something for us" defaultValue={""} />
                <div className="validate" />
              </div>
              <div className="form-group">
                <div data-netlify-recaptcha="true"></div>
              </div>
              <div className="mb-3">
                {/** Required hidden form field for the netlify form to work */}
                <input type="hidden" name="form-name" value="contact" />
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;