import React, { useEffect } from 'react';
import { useHero } from './data';

const mountHero = () => {
  // Hero typed
  if ($('.typed').length) {
    var typed_strings = $(".typed").data('typed-items');
    typed_strings = typed_strings.split(',')
    new Typed('.typed', {
      strings: typed_strings,
      loop: true,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000
    });
  }
};

const Hero = () => {
  useEffect(mountHero);
  const { hero_name, hero_image, hero_skills_list } = useHero();

  const heroContainerStyle = {
    background: `url("${hero_image}") top center`
  };

  const skills = hero_skills_list.reduce((list, element, skillIndex) => {
    return skillIndex !== 0 ? `${list}, ${element.skill}` : `${element.skill}`;
  }, '');

  return (
    <section id="hero" className="d-flex flex-column justify-content-center align-items-center" style={heroContainerStyle}>
      <div className="hero-container" data-aos="fade-in">
        <h1>{hero_name}</h1>
        <p>I'm <span className="typed" data-typed-items={skills} /></p>
      </div>
    </section>
  );
};

export default Hero