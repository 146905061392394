import React from 'react';
import NavMenu from './NavMenu';
import Profile from './Profile/index';

const Header = () => {
  return (
    <header id="header" >
      <div className="d-flex flex-column">
        <Profile />
        <NavMenu />
      </div>
    </header>
  );
};

export default Header;