import React from 'react';

const Avatar = ({ avatar }) => {
  const { name, mainAvatar } = avatar;
  return (
    <React.Fragment>
      <img src={mainAvatar} alt="Profile picture" className="img-fluid rounded-circle" />
      <h1 className="text-light"><a href="#">{name}</a></h1>
    </React.Fragment>
  );
}

export default Avatar;